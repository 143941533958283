import Alpine from 'alpinejs'
import { screenMatch } from 'helpers/screen'

let promises = {}
let screenCallbacks = []

window.addEventListener(
  'resize',
  () => {
    screenCallbacks.forEach((cb) => cb())
  },
  true
)

Alpine.data('screen', () => ({
  screenMatch: screenMatch(),
  isScreen(screen) {
    return this.screenMatch[screen]
  },
  init() {
    const cb = () => {
      this.screenMatch = screenMatch()
    }
    screenCallbacks = [...screenCallbacks, cb]

    this.destroy = () => {
      screenCallbacks = screenCallbacks.filter((fn) => fn !== cb)
    }
  },
}))

Alpine.data('modules', (type, config = {}) => {
  return {
    destroy() {},
    init() {
      const { data, ...rest } = config

      // apply default data
      if (typeof data === 'object')
      Object.keys(data).forEach((key) => {
        // target props of local scope in current stack
        this.$el._x_dataStack[0][key] = data[key]
      })

      if (!promises[type])
        promises[type] = import(/* webpackPrefetch: 0 */ `modules/${type}`)

      promises[type]
        .then((plugin) => {
          this.destroy = plugin.default(rest, this)
        })
        .catch((e) => console.log(e))
    },
  }
})

Alpine.data('tabs', function () {
  let active = 1
  const hash = window.location.hash
  if (hash) {
    const child = this.$el.querySelector(hash)
    if (child) {
      const index = child.dataset.index
      if (index) {
        active = parseInt(index)
      }
    }
  }

  return {
    active,
    init() {
      window.addEventListener('hashchange', () => {
        const hash = window.location.hash
        if (hash) {
          const child = this.$el.querySelector(hash)
          if (child) {
            const index = child.dataset.index
            if (index !== this.active) {
              this.active = parseInt(index)
            }
          }
        }
      })
    },
    transitions: {
      ['x-transition:enter']: `ease-out`,
      ['x-transition:enter-start']: `opacity-0`,
      ['x-transition:enter-end']: `opacity-100`,
      ['x-transition:leave']: `ease-in`,
      ['x-transition:leave-start']: `opacity-100`,
      ['x-transition:leave-end']: `opacity-0`,
    },
  }
})

Alpine.data('trackScrollHeight', function () {
  return {
    scrollHeight: this.$el.scrollHeight,
    init() {
      try {
        const observer = new ResizeObserver(() => {
          // listen to content size changes
          const { scrollHeight } = this.$el
          if (scrollHeight !== this.scrollHeight) this.scrollHeight = this.$el.scrollHeight
        })
  
        observer.observe(this.$el)
      } catch (error) {
        console.log('No ResizeObserver support')
      }
    },
  }
})

Alpine.data('frame', (path) => ({
  html: null,
  replaceSelf() {
    this.html && (this.$el.outerHTML = this.html)
  },
  init() {
    const url = new URL(path)
    fetch(url)
      .then((data) => data.text())
      .then((data) => (this.html = data))
      .catch((e) => console.warn('Error in frame module', e))
  },
}))

window.Alpine = Alpine
Alpine.start()


let currentHash = window.location.hash
let currentAnchor = currentHash?.substr(1)?.length ? document.getElementById(currentHash.substr(1)) : null
const scrollTo = destination => {
  if (destination) {
    window.scrollTo({
      top: destination.offsetTop,
      behavior: 'smooth',
    })
    history.pushState({}, '', currentHash)
  }
}
const scrollToHandler = (e) => {
  const { target } = e
  const currentTarget = target.closest(`a[href^="#"]`)
  if (!currentTarget) return
  const { pathname, hash } = currentTarget
  if (window.location.pathname === pathname && hash?.substr(1)?.length) {
    currentHash = hash
    currentAnchor = document.getElementById(currentHash.substr(1))
    if (currentAnchor) {
      scrollTo(currentAnchor)
      e.preventDefault()
    }
  }
}

if (currentAnchor) scrollTo(currentAnchor)
document.documentElement.addEventListener('click', scrollToHandler)